import {ref} from "vue";
export default function getTooltip(){
  const showTooltip = ref(false);
  const clientX     = ref(0);
  const clientY     = ref(0);
  const tooltipItem = ref(null);
  
  const onHover = (e, data) => {
    showTooltip.value = true;
    clientX.value     = e.clientX + 5;
    clientY.value     = e.clientY -20;
    tooltipItem.value = data;
  }

  const onMove = e => {
    showTooltip.value = true;
    clientX.value = e.clientX + 5;
    clientY.value = e.clientY -20;
  }

  return {
    onHover,
    onMove,
    showTooltip,
    clientX,
    clientY,
    tooltipItem
  }
}