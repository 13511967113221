<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

import FileSaver from "file-saver";

import Canvg, {
    presets
} from 'canvg';

import chroma from "chroma-js";

import CategoryList from '@/components/CategoryListUI.vue';
import Tooltip from "@/components/TooltipUI.vue";

import {mapCategory} from "@/graphsConfig.js";

import getFilters from "@/composables/filters.js";
import getTooltip from "@/composables/tooltip.js";
import getData from "@/composables/data.js";

const {
  colors : colorList, 
  noDataColor, 
  locationCol, 
  categoryCol,
  width,
  height
} = mapCategory;

export default {
  props : ["data", "config"],
  setup(props){
    
    const store      = useStore();
    
    const updateValues = (e, i) => {
      values.value[i] = e.target.value;
    }

    const getStyle = loc => {
      let item = currentData.value.find(d => d.location == loc);
      return {
        fill : item ? item.color :noDataColor
      }
    }

    const getDataFromId = e => {
      const id = e.target.id;
      const data = currentData.value.filter(d => d.locObj).find(d => d.locObj.CVE_MUN == id);

      return data;
    }

    const {onHover, onMove, showTooltip, clientX, clientY, tooltipItem} = getTooltip();

    const location   = computed( () => props.config.fields.find(d => d.label == locationCol).column );
    const category   = computed( () => props.config.fields.find(d => d.label == categoryCol).column);
    const categories = computed( () => [...new Set(props.data.map( d => d[category.value]))].sort() );
    

    const {filters} = getFilters(props);

    const colors = computed( () => {
      // let categories = [...new Set(mappedData.value.map(d => d.location))];
      if(categories.value.length < colorList.length){
        return colorList;
      }
      else{
        return chroma.scale(colorList).mode('lch').colors(categories.value.length);
      }
    });

    const mappedData = computed( () => {
      let filters    = props.config.filters;

      let data = props.data.map(d => {
        let item = {};
        let catNum = categories.value.length;
        let colors =   catNum < colorList.length  ? colorList : chroma.scale(colorList).mode('lch').colors(catNum)
        item.locObj    = store.getters.translateLocation(d[location.value]);
        item.location  = item.locObj ? item.locObj.CVE_MUN : null;
        item.category  = d[category.value];
        
        item.color     = colors[categories.value.indexOf(item.category) ];
        for(let f of filters){
          item[f] = d[f];
        }
        item.data     = d;
        return item;
      });
      return data;
    })
    
    const values      = ref( filters.value.map( d => d.items[0]) );

    const {currentData} = getData(props, filters, mappedData, null, null, values)

    const svg    = ref(null);

    const saveImage = async () => {
      const canvas = new OffscreenCanvas(width, height);
      const ctx    = canvas.getContext('2d');
      const v      = await Canvg.fromString(ctx, svg.value.outerHTML, presets.offscreen());

      await v.render();

      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);

      FileSaver.saveAs(pngUrl, "image.png")
    }

    return {
      showTooltip,
      clientX,
      clientY,
      tooltipItem,

      svg,
      width,
      height,
      categories,
      colors,
      filters,
      values,
      updateValues,
      currentData,
      getStyle,
      onHover,
      onMove,
      saveImage,
      getDataFromId
    }
  },

  components : {
    CategoryList,
    Tooltip
  }
}
</script>
<template>
  <div class="sg_viz">

    <div v-if="filters.length" class="col-12">
      <h2 class="subtite_indicator text-center mb-4 pb-4">Datos <span v-for="(filter, i) of filters" :key="`filter-${i}`">
        <span v-if="filter.name == 'Anio'">
         <span v-for="(opt,j) of filter.items" :key="`fil-${opt}-${i}-${j}`">
               <span v-if="j == 0">{{opt}} -</span>  
               <span v-if="j == filter.items.length - 1">{{opt}}</span>  
          </span>
        </span>
      </span></h2>
    </div>
    <div class="sg_dataviz">
      <div class="row">
      <div class="col-3">
    <!-- category list -->
      <div class="sg_colorguide_viz">
        <category-list :categories="categories" :colors="colors" :current="currentData" />
      </div>
      </div>
      <div class="col-6">
    <svg ref="svg" xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width="100%" height="100%" :viewBox="`0 0 ${width} ${height}`" stroke-linecap="round" stroke-linejoin="round" class="sg_svg map">
      <g id="09mun">
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 391.42 169.97 417.72 239.13 417.84 240.98 404.86 244.82 391.17 242.87 386.97 244.25 380.19 252.59 379.14 257.19 338.41 250.47 332.59 249.03 333.5 243.32 328.36 241.71 328.48 239.74 328.9 230.84 309.68 225.41 298.12 229.42 271.26 222.18 288.41 168.72 291.84 154.39 302.17 146.88 326.17 154.13 338.72 159.28 354.08 163.68 361.61 159.18 372.82 163.87 391.42 169.97 Z" id="002" :style="getStyle('002')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 457.74 444.11 458.31 448.51 460.84 456.16 469.45 469.56 472.74 481.02 483.98 497.2 500.61 512.77 498.44 536.59 498.29 538.57 497.13 551.15 494.89 551.24 491.8 551.4 490.42 551.48 489.58 551.52 481.57 551.9 457.48 545.67 433.62 535.6 418.79 551.92 413.09 549.81 412.3 549.64 401.87 554.93 392.39 557.13 383.2 549.1 367.93 546.87 367.63 546.82 366.66 546.69 365.54 546.54 355.02 545.11 354.59 545.04 344.12 545.02 320.65 547.36 320.25 547.33 319.53 547.23 318.55 547.05 300.14 540.51 300.55 538.56 300.59 538.56 309.73 529.75 308.79 529.66 308.66 529.48 308.65 529.35 320.47 509.78 320.9 494.37 314.22 484.7 323.81 482.38 326.31 481.92 327.42 481.88 327.77 481.38 339.56 469.55 343.13 463.64 362.11 443.91 364.12 439.24 366.28 440.51 366.57 440.62 367.77 440.95 368.83 441.06 370.05 441.15 424.01 444.22 434.24 444.92 440.28 440.94 454.49 442.48 456.49 442.7 456.83 442.74 457.53 442.82 457.57 442.82 457.74 444.11 Z" id="003" :style="getStyle('003')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 206.06 356.54 207.19 364.87 205.04 369.8 198.82 374.28 218.39 375.61 221.39 389.64 203.99 401.57 196.25 408.29 179.12 414.35 185.83 422.79 185.34 431.76 196.06 436.87 199.64 458.98 190.39 464.81 182.13 472.54 163.42 480.27 150.84 483.32 145.8 494.39 139.6 496.02 138.7 504.14 140.49 511.06 130.3 518.86 124.75 511.37 119.84 516.24 122.94 524.93 112.14 537.28 100.59 539.16 94.55 548.77 90.76 559.05 93.44 570.16 91.68 577.24 93.45 584.64 90.34 598.41 78.58 616.8 78.81 643.75 77.31 677.83 72.8 677.92 65.09 667.82 43.44 661.83 43.95 649.78 48.11 627.01 52.29 618.77 49.42 611.89 39.03 608.55 32.4 600.12 6.64 596.77 1 592.96 29.08 557.13 17.26 547.4 16.63 538.64 59.41 493.7 62.3 488.19 64.47 476.43 69.88 466.24 69.48 451.41 73.41 450.32 78.35 442.27 84.11 437.59 86.29 431.63 94.09 427.36 98.42 418.79 108.42 416.7 113.61 406.79 120.55 406.32 120.7 420.2 124.86 424.3 131.31 421.37 152.01 401.43 160.53 396.58 172.47 387.62 174.82 382.65 180.2 381.32 187.36 366.65 195.74 364.51 206.06 356.54 Z" id="004" :style="getStyle('004')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 591.21 268.95 590.79 269.37 590.7 269.45 589.98 270.2 588.02 272.25 569.76 279.08 540.04 269.76 534.82 268.99 523.02 277.24 511.62 266.28 495.38 269.05 495.19 268.98 482.73 264.45 482.78 264.28 475.76 261.51 466.27 254.05 457.08 250.9 456.76 250.81 455.89 250.54 447.1 247.62 437.12 241.77 417.84 240.98 417.72 239.13 391.42 169.97 380.53 141.57 358.62 130.09 364.76 124.22 357.25 118.76 358.01 115.41 371.7 120.35 387.7 124.76 393.92 114.35 389.79 109 402.87 91.04 384.43 82.74 390.69 71.87 389.7 67.54 393.37 62.27 399.63 58.87 403.22 61.58 412.44 51.47 412.79 46.12 416.82 39.66 424.52 33.63 427.64 22.84 435.43 13.24 448.15 8.99 454.33 1 465.26 5.07 463.93 16.59 471.32 25.36 476.67 46.16 483.66 53.37 469.34 68.84 469.52 73.76 459.34 82.99 456.16 89.92 441.42 107.49 447.45 109.39 445.91 126.68 450.21 135.46 459.38 144.7 471.8 154.53 488.51 155.91 503.76 151.58 541.7 168.02 566.79 177.62 559.03 195.84 591.21 268.95 Z" id="005" :style="getStyle('005')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 577.56 361.9 572.46 395.98 562.09 394.19 529.61 377.4 527.24 382.67 519.92 381.33 507.06 401.59 467.08 396.85 456.64 396.25 456.53 408.92 443.06 406.42 432.18 406.13 440.73 365.22 429 364.76 430.12 357.97 442.42 357.22 450.58 355.11 470.56 352.81 506.16 346.12 536.04 350.31 540.26 349.82 555.41 325.9 559.11 324.16 577.96 324 581.97 321.64 577.56 361.9 Z" id="006" :style="getStyle('006')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 457.53 442.82 456.83 442.74 456.49 442.7 454.49 442.48 440.28 440.94 434.24 444.92 424.01 444.22 432.18 406.13 443.06 406.42 456.53 408.92 456.64 396.25 467.08 396.85 507.06 401.59 519.92 381.33 527.24 382.67 529.61 377.4 562.09 394.19 572.46 395.98 577.56 361.9 648.57 394 667.71 403.99 701.96 424.15 697.04 441.92 726.66 469.83 735.06 481.6 761.14 503.5 760.05 503.45 750.36 502.96 729.29 503.78 724.64 502.06 684.26 500.19 638.02 514.84 610.81 522.22 604.85 528.24 599.07 523.62 585.74 528.9 574.29 542.84 567.66 540.5 565.07 548.1 569.09 556.25 570.98 564.97 557.2 579.46 545.97 570.55 522.96 543.39 503.18 518.91 500.61 512.77 483.98 497.2 472.74 481.02 469.45 469.56 460.84 456.16 458.31 448.51 457.74 444.11 457.57 442.82 457.53 442.82 Z" id="007" :style="getStyle('007')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 273.5 528.14 265.98 530.71 258.53 538.3 250.99 541.71 248.18 549.65 249.88 555.44 241.28 561.96 233.17 562.43 230.14 567.28 233.87 573.76 226.54 588.51 226.01 595.37 229.26 607.96 222.63 617.91 227.16 640.02 224.89 645.6 217.74 652.05 213.66 659.37 215.11 663.09 211.1 673.15 200.26 681.78 191.1 685.57 171.58 699.58 170.72 706.19 163.96 712.28 156.1 714.53 135.88 712.98 129.2 705.73 122.51 705.77 104.93 692.61 93.78 687.63 94.18 684.25 81.19 677.76 79.2 666.66 89.47 650.56 96.56 632.35 111.29 630.6 118.56 628.37 133.84 617.72 136.96 610.74 148.97 606.29 158.46 587.61 173.15 579.02 167.8 571.67 174.77 562.06 176.63 552.74 175.52 546.36 181.19 542.27 177.13 537.62 180.44 525.58 187.1 525.93 194.86 517.94 207.25 501.27 229.13 497.69 235.28 505.34 240.82 504.77 252.31 495.12 283.01 482.8 297.59 482.51 287.52 500 271.67 512.38 270.58 516.06 273.5 528.14 Z" id="008" :style="getStyle('008')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 685.37 688.39 687.1 688.78 699.47 692.24 704.55 690.67 727.87 710.8 735.02 711.97 745.96 719.25 749.13 722.19 747.51 733.24 748.87 743.02 747.52 758.39 749.94 763.49 762.27 778.27 769.17 782.79 776.29 798.06 748.14 805.95 772.08 833.98 749.85 844.6 759.93 864.98 766.67 884.98 764.42 901.42 763.74 919.01 759.65 935.35 751.23 953.62 746.42 958.73 736.24 965.48 726.28 975.05 717.64 966.96 632.32 954.55 605.61 972.61 574.43 1024.33 451.08 998.34 432.39 947.17 430.32 947.01 410.91 925.95 379.93 894.18 395.5 860.69 400.21 847.9 401.23 831.64 405.65 813.7 410.92 814.39 417.23 818.92 422.4 828.39 437.98 835.69 442.04 828.3 488.1 744.11 494.9 737.14 495.39 730.79 500.36 723.81 506.98 722.9 509.1 717.43 516.37 718.26 630.07 694.7 682.79 689.99 683.69 689.91 685.37 688.39 Z" id="009" :style="getStyle('009')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 300.14 540.51 282.01 536.86 273.5 528.14 270.58 516.06 271.67 512.38 287.52 500 297.59 482.51 283.01 482.8 252.31 495.12 240.82 504.77 235.28 505.34 229.13 497.69 207.25 501.27 194.86 517.94 187.1 525.93 180.44 525.58 177.13 537.62 181.19 542.27 175.52 546.36 176.63 552.74 174.77 562.06 167.8 571.67 173.15 579.02 158.46 587.61 148.97 606.29 136.96 610.74 133.84 617.72 118.56 628.37 111.29 630.6 96.56 632.35 89.47 650.56 79.2 666.66 81.19 677.76 77.31 677.83 78.81 643.75 78.58 616.8 90.34 598.41 93.45 584.64 91.68 577.24 93.44 570.16 90.76 559.05 94.55 548.77 100.59 539.16 112.14 537.28 122.94 524.93 119.84 516.24 124.75 511.37 130.3 518.86 140.49 511.06 138.7 504.14 139.6 496.02 145.8 494.39 150.84 483.32 163.42 480.27 182.13 472.54 190.39 464.81 199.64 458.98 196.06 436.87 185.34 431.76 185.83 422.79 179.12 414.35 196.25 408.29 203.99 401.57 221.39 389.64 230.74 387.62 235.91 384.18 238.01 376.74 249.23 373 266.63 371.05 291.19 362.45 308.28 360.09 325.6 356.22 325.73 361.41 331.51 371.54 332.65 374.29 326.6 395.15 325.73 412.87 328.32 425.67 327.68 431.87 337.85 435.18 348.16 432.93 352.78 428.76 364.12 439.24 362.11 443.91 343.13 463.64 339.56 469.55 327.77 481.38 327.42 481.88 326.31 481.92 323.81 482.38 314.22 484.7 320.9 494.37 320.47 509.78 308.65 529.35 308.66 529.48 308.79 529.66 309.73 529.75 300.59 538.56 300.55 538.56 300.14 540.51 Z" id="010" :style="getStyle('010')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 683.69 689.91 682.79 689.99 630.07 694.7 657.84 662.08 660.66 654.34 665.78 652.57 670.12 643.88 673.57 642.13 661.55 629.41 655.67 626.4 638.24 622.21 631.74 621.9 619.26 606.37 608.91 600.46 596.06 596.33 584.39 589.96 578.56 589.47 565.32 584.8 557.2 579.46 570.98 564.97 569.09 556.25 565.07 548.1 567.66 540.5 574.29 542.84 585.74 528.9 599.07 523.62 604.85 528.24 610.81 522.22 638.02 514.84 684.26 500.19 724.64 502.06 729.29 503.78 750.36 502.96 760.05 503.45 761.14 503.5 765.92 507.53 756.41 519.75 753.01 538.25 748.41 539.97 740.5 591.84 733.95 627.32 730.92 639.28 750.73 645.26 745.85 678.57 799 695.44 794.77 709.77 778.74 704.94 773.36 711.59 766.75 710.67 756.92 712.54 745.96 719.25 735.02 711.97 727.87 710.8 704.55 690.67 699.47 692.24 687.1 688.78 685.37 688.39 683.69 689.91 Z" id="011" :style="getStyle('011')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 497.13 551.15 496.31 560.21 493.72 561.39 453.94 578.31 434.96 583.7 421.72 584.02 419.19 613.91 419.26 625.92 424.84 636.33 420.53 645.62 425.73 652.73 422.98 657.19 416.82 655.26 416.17 664.54 412.2 665.21 415.34 674.23 404.44 674.89 398.56 690.29 402.88 693.66 396.65 700.33 426.87 709.56 436.83 720.15 437.93 726.94 444.21 739.73 441.23 755.44 447.99 763.05 442.13 769.74 438 779.46 441.63 792.01 441.29 805.08 437.43 814.04 437.78 827.4 442.04 828.3 437.98 835.69 422.4 828.39 417.23 818.92 410.92 814.39 405.65 813.7 401.23 831.64 400.21 847.9 395.5 860.69 379.93 894.18 410.91 925.95 430.32 947.01 260.62 934.45 163.05 867.47 146.31 839.47 131.29 790.08 117.82 756.3 114.21 734.15 111.49 727.57 109.6 713.32 92.47 698.71 93.78 687.63 104.93 692.61 122.51 705.77 129.2 705.73 135.88 712.98 156.1 714.53 163.96 712.28 170.72 706.19 171.58 699.58 191.1 685.57 200.26 681.78 211.1 673.15 215.11 663.09 213.66 659.37 217.74 652.05 224.89 645.6 227.16 640.02 222.63 617.91 229.26 607.96 226.01 595.37 226.54 588.51 233.87 573.76 230.14 567.28 233.17 562.43 241.28 561.96 249.88 555.44 248.18 549.65 250.99 541.71 258.53 538.3 265.98 530.71 273.5 528.14 282.01 536.86 300.14 540.51 318.55 547.05 319.53 547.23 320.25 547.33 320.65 547.36 344.12 545.02 354.59 545.04 355.02 545.11 365.54 546.54 366.66 546.69 367.63 546.82 367.93 546.87 383.2 549.1 392.39 557.13 401.87 554.93 412.3 549.64 413.09 549.81 418.79 551.92 433.62 535.6 457.48 545.67 481.57 551.9 489.58 551.52 490.42 551.48 491.8 551.4 494.89 551.24 497.13 551.15 Z" id="012" :style="getStyle('012')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 497.13 551.15 498.29 538.57 498.44 536.59 500.61 512.77 503.18 518.91 522.96 543.39 545.97 570.55 557.2 579.46 565.32 584.8 578.56 589.47 584.39 589.96 596.06 596.33 608.91 600.46 619.26 606.37 631.74 621.9 638.24 622.21 655.67 626.4 661.55 629.41 673.57 642.13 670.12 643.88 665.78 652.57 660.66 654.34 657.84 662.08 630.07 694.7 516.37 718.26 509.1 717.43 506.98 722.9 500.36 723.81 495.39 730.79 494.9 737.14 488.1 744.11 442.04 828.3 437.78 827.4 437.43 814.04 441.29 805.08 441.63 792.01 438 779.46 442.13 769.74 447.99 763.05 441.23 755.44 444.21 739.73 437.93 726.94 436.83 720.15 426.87 709.56 396.65 700.33 402.88 693.66 398.56 690.29 404.44 674.89 415.34 674.23 412.2 665.21 416.17 664.54 416.82 655.26 422.98 657.19 425.73 652.73 420.53 645.62 424.84 636.33 419.26 625.92 419.19 613.91 421.72 584.02 434.96 583.7 453.94 578.31 493.72 561.39 496.31 560.21 497.13 551.15 Z" id="013" :style="getStyle('013')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 366.28 440.51 364.12 439.24 352.78 428.76 348.16 432.93 337.85 435.18 327.68 431.87 328.32 425.67 325.73 412.87 326.6 395.15 332.65 374.29 331.51 371.54 356.93 366.83 366.51 363.65 393.02 355.62 428.58 356.74 430.12 357.97 429 364.76 440.73 365.22 432.18 406.13 424.01 444.22 370.05 441.15 368.83 441.06 367.77 440.95 366.57 440.62 366.28 440.51 Z" id="014" :style="getStyle('014')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 455.89 250.54 456.76 250.81 457.08 250.9 455.81 252.57 455.32 253.29 450.79 275.02 451.18 275.59 455.44 283.08 450 320.27 445.88 337.39 450.58 355.11 442.42 357.22 430.12 357.97 428.58 356.74 393.02 355.62 366.51 363.65 365.3 356.68 340.44 348.54 352.73 327.01 352.97 318.91 370.17 291.55 375.37 280.9 377.98 269.32 379.14 257.19 380.19 252.59 386.97 244.25 391.17 242.87 404.86 244.82 417.84 240.98 437.12 241.77 447.1 247.62 455.89 250.54 Z" id="015" :style="getStyle('015')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 328.48 239.74 328.36 241.71 333.5 243.32 332.59 249.03 338.41 250.47 379.14 257.19 377.98 269.32 375.37 280.9 370.17 291.55 352.97 318.91 352.73 327.01 340.44 348.54 365.3 356.68 366.51 363.65 356.93 366.83 331.51 371.54 325.73 361.41 325.6 356.22 308.28 360.09 291.19 362.45 266.63 371.05 249.23 373 238.01 376.74 235.91 384.18 230.74 387.62 221.39 389.64 218.39 375.61 198.82 374.28 205.04 369.8 207.19 364.87 206.06 356.54 220.15 347 231.39 342.01 241.57 339.37 247.78 332.08 248.98 326.8 260.17 317.55 267.33 308.18 261.13 304.86 258.6 290.79 272.26 281.17 273.41 263.87 282.22 257.59 288.11 237.12 298.12 229.42 309.68 225.41 328.9 230.84 328.48 239.74 Z" id="016" :style="getStyle('016')" />
        <path @mouseover="e => onHover(e, getDataFromId(e))" @mousemove="onMove" @mouseout="showTooltip = false" d="M 482.78 264.28 482.73 264.45 495.19 268.98 495.38 269.05 511.62 266.28 523.02 277.24 534.82 268.99 540.04 269.76 569.76 279.08 588.02 272.25 589.98 270.2 590.7 269.45 590.79 269.37 591.21 268.95 599.5 283.64 596.21 288.76 583.08 317.64 581.97 321.64 577.96 324 559.11 324.16 555.41 325.9 540.26 349.82 536.04 350.31 506.16 346.12 470.56 352.81 450.58 355.11 445.88 337.39 450 320.27 455.44 283.08 451.18 275.59 450.79 275.02 455.32 253.29 455.81 252.57 457.08 250.9 466.27 254.05 475.76 261.51 482.78 264.28 Z" id="017" :style="getStyle('017')" />
      </g>
    </svg>
    </div>

      <!-- the filters -->
      <div v-if="filters.length" class="col-sm-3 sg_viz_filter">
        <ul class="row sg_filters">
          <li v-for="(filter, i) of filters" :key="`filter-${i}`" class="col">
            {{filter.name}}
            <select @change="e => updateValues(e, i)">
              <option v-for="(opt,j) of filter.items" :key="`fil-${opt}-${i}-${j}`">
                {{opt}}
              </option>
            </select>
          </li>
        </ul>
      </div>
      </div>
    </div>
    <!-- the map -->
    <p><button @click.prevent="saveImage" class="btn_image">Guardar imagen <b class="sg_i_image"></b></button></p>
    <tooltip  :clientX="clientX" :clientY="clientY" :show="showTooltip" :labels="config.labels" :item="tooltipItem" />
  </div>
</template>