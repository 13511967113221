import { computed } from "vue";

export default function getData(props, filters, mappedData = null, xColumn, yColumn, values, cColumn) {
  
  const barsMappedData = computed( () => {
    let filters = props.config.filters;
    let data    = props.data.map(d => {
      let item = {};
      item.id = new Date().valueOf();
      item.category = d[xColumn.value];
      item.value    = d[yColumn.value];
      for(let f of filters){
        item[f] = d[f];
      }
      item.data = d;
      return item;
    });
    return data;
  });

  const stackedMappedData = computed ( () => {
    let filters = props.config.filters;
    let data = props.data.map( d => {
      let item = {};
      item.id = new Date().valueOf();
      item.category = d[xColumn.value];
      item.value    = d[yColumn.value];
      item.stack    = d[cColumn.value];
      for(let f of filters){
        item[f] = d[f];
      }
      item.data = d;
      return item;
    });

    return data;
  });

  const currentData = computed( () => {
    return mappedData.value.filter(d => {
      let check = [];
      for(let i = 0; i< filters.value.length; i++){
        check.push(d[filters.value[i].name] == values.value[i])
      }
      return check.filter(d => d).length == filters.value.length;
    })
  })

  return {
    barsMappedData,
    stackedMappedData,
    currentData
  }
}