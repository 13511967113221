<script>
import {computed} from "vue";
export default {
  props : ["categories", "colors", "current"],
  setup(props){
    let visible = computed( () => props.current.map(( d => d.category)).filter(d => d) );
    const filteredArray = visible.value.filter(value => props.categories.includes(value));

    return {
      visible : filteredArray.length ? visible : props.categories
    }
  }
}
</script>
<template>
  <ul v-if="categories" class="sg_cat gf_inline_block">
    <li v-for="(cat, i) of categories" :key="`cat-${cat}-${i}}`" :style="{
      display : visible.indexOf(cat) == -1 ? 'none' : 'inline-block'
    }">
      <span class="category-item" :style="{backgroundColor : colors[i]}"></span> {{cat}}
    </li>
  </ul>
</template>

<style>
.category-item{
  display: inline-block;
  width: 1em;
  height: 1em;
}
</style>