<script>
import {computed} from "vue";
export default {
  props : ["clientX", "clientY", "show", "labels", "item"],
  setup(props){
    const items = computed( () => {
      let keys = props.item ? Object.keys(props.item.data) : null;

      if(keys){
        return keys.map( key => ({label : key, value : props.item.data[key]}))
      }
      else{
        return []
      }
    });
    return {
      items
    }
  }
}
</script>
<template>
  <ul class="tooltip" :style="{ top: `${clientY}px`, left: `${clientX}px` }" v-if="show && item">
    <li v-for="(it, i) of items" :key="`lb-${i}`">
      <strong>{{it.label}}</strong>: {{it.value}}
    </li>
  </ul>  
</template>

<style scoped>
.tooltip {
  background: white;
  color: #444;
  font-family: 'Source Sans Pro',Helvetica, sans-serif;  
  padding: 10px 10px;
  position: fixed;
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}
</style>